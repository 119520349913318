/* eslint-disable */

/* =========================================================
 * foundation-datepicker.js
 * Copyright 2015 Peter Beno, najlepsiwebdesigner@gmail.com, @benopeter
 * project website http://foundation-datepicker.peterbeno.com
 * ========================================================= */

// This file is a modified version of the original file
// See
//  - https://github.com/decidim/decidim/pull/1207
//  - https://github.com/decidim/decidim/pull/2512
//  - https://github.com/decidim/decidim/pull/3515

!(function($) {

  function UTCDate() {
    return new Date(Date.UTC(...arguments));
  }

  function UTCToday() {
    let today = new Date();
    return UTCDate(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate());
  }

  let Datepicker = function(element, options) {
    let that = this;

    this.element = $(element);
    this.autoShow = (options.autoShow == undefined ? true : options.autoShow);
    this.appendTo = options.appendTo || "body";
    this.closeButton = options.closeButton;
    this.language = options.language || this.element.data("date-language") || "en";
    this.language = this.language in dates ? this.language : this.language.split("-")[0]; // Check if "de-DE" style date is available, if not language should fallback to 2 letter code eg "de"
    this.language = this.language in dates ? this.language : "en";
    this.isRTL = dates[this.language].rtl || false;
    this.format = DPGlobal.parseFormat(options.format || this.element.data("date-format") || dates[this.language].format || "mm/dd/yyyy");
    this.formatText = options.format || this.element.data("date-format") || dates[this.language].format || "mm/dd/yyyy";
    this.isInline = false;
    this.isInput = this.element.is("input");
    this.component = this.element.is(".date") ? this.element.find(".prefix, .postfix") : false;
    this.hasInput = this.component && this.element.find("input").length;
    this.disableDblClickSelection = options.disableDblClickSelection;
    this.onRender = options.onRender || function() {};
    if (this.component && this.component.length === 0) {
      this.component = false;
    }
    this.linkField = options.linkField || this.element.data("link-field") || false;
    this.linkFormat = DPGlobal.parseFormat(options.linkFormat || this.element.data("link-format") || "yyyy-mm-dd hh:ii:ss");
    this.minuteStep = options.minuteStep || this.element.data("minute-step") || 5;
    this.pickerPosition = options.pickerPosition || this.element.data("picker-position") || "bottom-right";
    this.initialDate = options.initialDate || null;
    this.faCSSprefix = options.faCSSprefix || "fa";
    this.leftArrow = options.leftArrow || `<i class="${this.faCSSprefix} ${this.faCSSprefix}-chevron-left fi-arrow-left"/>`;
    this.rightArrow = options.rightArrow || `<i class="${this.faCSSprefix} ${this.faCSSprefix}-chevron-right fi-arrow-right"/>`;
    this.closeIcon = options.closeIcon || `<i class="${this.faCSSprefix} ${this.faCSSprefix}-remove ${this.faCSSprefix}-times fi-x"></i>`;


    this.minView = 0;
    if ("minView" in options) {
      this.minView = options.minView;
    } else if ("minView" in this.element.data()) {
      this.minView = this.element.data("min-view");
    }
    this.minView = DPGlobal.convertViewMode(this.minView);

    this.maxView = DPGlobal.modes.length - 1;
    if ("maxView" in options) {
      this.maxView = options.maxView;
    } else if ("maxView" in this.element.data()) {
      this.maxView = this.element.data("max-view");
    }
    this.maxView = DPGlobal.convertViewMode(this.maxView);

    this.startViewMode = "month";
    if ("startView" in options) {
      this.startViewMode = options.startView;
    } else if ("startView" in this.element.data()) {
      this.startViewMode = this.element.data("start-view");
    }
    this.startViewMode = DPGlobal.convertViewMode(this.startViewMode);
    this.viewMode = this.startViewMode;

    if (!("minView" in options) && !("maxView" in options) && !(this.element.data("min-view")) && !(this.element.data("max-view"))) {
      this.pickTime = false;
      if ("pickTime" in options) {
        this.pickTime = options.pickTime;
      }
      if (this.pickTime == true) {
        this.minView = 0;
        this.maxView = 4;
      } else {
        this.minView = 2;
        this.maxView = 4;
      }
    }

    this.forceParse = true;
    if ("forceParse" in options) {
      this.forceParse = options.forceParse;
    } else if ("dateForceParse" in this.element.data()) {
      this.forceParse = this.element.data("date-force-parse");
    }


    this.picker = $(DPGlobal.template(this.leftArrow, this.rightArrow, this.closeIcon)).
      appendTo(this.isInline ? this.element : this.appendTo).
      on({
        click: $.proxy(this.click, this),
        mousedown: $.proxy(this.mousedown, this)
      });
    if (this.closeButton) {
      this.picker.find("a.datepicker-close").show();
    } else {
      this.picker.find("a.datepicker-close").hide();
    }

    if (this.isInline) {
      this.picker.addClass("datepicker-inline");
    } else {
      this.picker.addClass("datepicker-dropdown dropdown-menu");
    }
    if (this.isRTL) {
      this.picker.addClass("datepicker-rtl");

      this.picker.find(".date-switch").each(function() {
        $(this).parent().prepend($(this).siblings(".next"));
        $(this).parent().append($(this).siblings(".prev"));
      })
      this.picker.find(".prev, .next").toggleClass("prev next");

    }
    $(document).on("mousedown", function(e) {
      if (that.isInput && e.target === that.element[0]) {
        return;
      }

      // Clicked outside the datepicker, hide it
      if ($(e.target).closest(".datepicker.datepicker-inline, .datepicker.datepicker-dropdown").length === 0) {
        that.hide();
      }
    });

    this.autoclose = true;
    if ("autoclose" in options) {
      this.autoclose = options.autoclose;
    } else if ("dateAutoclose" in this.element.data()) {
      this.autoclose = this.element.data("date-autoclose");
    }

    this.keyboardNavigation = true;
    if ("keyboardNavigation" in options) {
      this.keyboardNavigation = options.keyboardNavigation;
    } else if ("dateKeyboardNavigation" in this.element.data()) {
      this.keyboardNavigation = this.element.data("date-keyboard-navigation");
    }

    this.todayBtn = (options.todayBtn || this.element.data("date-today-btn") || false);
    this.todayHighlight = (options.todayHighlight || this.element.data("date-today-highlight") || false);

    this.calendarWeeks = false;
    if ("calendarWeeks" in options) {
      this.calendarWeeks = options.calendarWeeks;
    } else if ("dateCalendarWeeks" in this.element.data()) {
      this.calendarWeeks = this.element.data("date-calendar-weeks");
    }
    if (this.calendarWeeks)
    {this.picker.find("tfoot th.today").
      attr("colspan", function(i, val) {
        return parseInt(val) + 1;
      });}

    this.weekStart = ((options.weekStart || this.element.data("date-weekstart") || dates[this.language].weekStart || 0) % 7);
    this.weekEnd = ((this.weekStart + 6) % 7);
    this.startDate = -Infinity;
    this.endDate = Infinity;
    this.daysOfWeekDisabled = [];
    this.datesDisabled = [];
    this.setStartDate(options.startDate || this.element.data("date-startdate"));
    this.setEndDate(options.endDate || this.element.data("date-enddate"));
    this.setDaysOfWeekDisabled(options.daysOfWeekDisabled || this.element.data("date-days-of-week-disabled"));
    this.setDatesDisabled(options.datesDisabled || this.element.data("dates-disabled"));

    if (this.initialDate != null) {
      this.date = this.viewDate = DPGlobal.parseDate(this.initialDate, this.format, this.language);
      this.setValue();
    }

    this.fillDow();
    this.fillMonths();
    this.update();

    this.showMode();

    if (this.isInline) {
      this.show();
    }

    this._attachEvents();
  };

  Datepicker.prototype = {
    constructor: Datepicker,

    _events: [],
    _attachEvents: function() {
      this._detachEvents();
      if (this.isInput) { // single input
        if (!this.keyboardNavigation) {
          this._events = [
            [this.element, {
              focus: (this.autoShow) ? $.proxy(this.show, this) : function() {}
            }]
          ];
        } else {
          this._events = [
            [this.element, {
              focus: (this.autoShow) ? $.proxy(this.show, this) : function() {},
              keyup: $.proxy(this.update, this),
              keydown: $.proxy(this.keydown, this),
              click: (this.element.attr("readonly")) ? $.proxy(this.show, this) : function() {}
            }]
          ];
        }
      }
      else if (this.component && this.hasInput) { // component: input + button
        this._events = [
          // For components that are not readonly, allow keyboard nav
          [this.element.find("input"), {
            focus: (this.autoShow) ? $.proxy(this.show, this) : function() {},
            keyup: $.proxy(this.update, this),
            keydown: $.proxy(this.keydown, this)
          }],
          [this.component, {
            click: $.proxy(this.show, this)
          }]
        ];
      } else if (this.element.is("div")) { // inline datepicker
        this.isInline = true;
      } else {
        this._events = [
          [this.element, {
            click: $.proxy(this.show, this)
          }]
        ];
      }

      if (this.disableDblClickSelection) {
        this._events[this._events.length] = [
          this.element, {
            dblclick: function(e) {
              e.preventDefault();
              e.stopPropagation();
              $(this).blur()
            }
          }
        ];
      }

      for (var el, ev, i = 0; i < this._events.length; i++) {
        el = this._events[i][0];
        ev = this._events[i][1];
        el.on(ev);
      }
    },
    _detachEvents: function() {
      for (var el, ev, i = 0; i < this._events.length; i++) {
        el = this._events[i][0];
        ev = this._events[i][1];
        el.off(ev);
      }
      this._events = [];
    },

    show: function(e) {
      this.picker.show();
      this.height = this.component ? this.component.outerHeight() : this.element.outerHeight();
      this.update();
      this.place();
      $(window).on("resize", $.proxy(this.place, this));
      if (e) {
        e.stopPropagation();
        e.preventDefault();
      }
      this.element.trigger({
        type: "show",
        date: this.date
      });
    },

    hide: function(e) {
      if (this.isInline) {return;}
      if (!this.picker.is(":visible")) {return;}
      this.picker.hide();
      $(window).off("resize", this.place);
      this.viewMode = this.startViewMode;
      this.showMode();
      if (!this.isInput) {
        $(document).off("mousedown", this.hide);
      }

      if (
        this.forceParse &&
                (
                  this.isInput && this.element.val() ||
                    this.hasInput && this.element.find("input").val()
                )
      )
      {this.setValue();}
      this.element.trigger({
        type: "hide",
        date: this.date
      });
    },

    remove: function() {
      this._detachEvents();
      this.picker.remove();
      delete this.element.data().datepicker;
    },

    getDate: function() {
      let d = this.getUTCDate();
      return new Date(d.getTime() + (d.getTimezoneOffset() * 60000));
    },

    getUTCDate: function() {
      return this.date;
    },

    setDate: function(d) {
      this.setUTCDate(new Date(d.getTime() - (d.getTimezoneOffset() * 60000)));
    },

    setUTCDate: function(d) {
      this.date = d;
      this.setValue();
    },

    setValue: function() {
      let formatted = this.getFormattedDate();
      if (!this.isInput) {
        if (this.component) {
          this.element.find("input").val(formatted);
        }
        this.element.data("date", formatted);
      } else {
        this.element.val(formatted);
      }
    },

    getFormattedDate: function(format) {
      if (format === undefined)
      {format = this.format;}
      return DPGlobal.formatDate(this.date, format, this.language);
    },

    setStartDate: function(startDate) {
      this.startDate = startDate || -Infinity;
      if (this.startDate !== -Infinity) {
        this.startDate = DPGlobal.parseDate(this.startDate, this.format, this.language);
      }
      this.update();
      this.updateNavArrows();
    },

    setEndDate: function(endDate) {
      this.endDate = endDate || Infinity;
      if (this.endDate !== Infinity) {
        this.endDate = DPGlobal.parseDate(this.endDate, this.format, this.language);
      }
      this.update();
      this.updateNavArrows();
    },

    setDaysOfWeekDisabled: function(daysOfWeekDisabled) {
      this.daysOfWeekDisabled = daysOfWeekDisabled || [];
      if (!$.isArray(this.daysOfWeekDisabled)) {
        this.daysOfWeekDisabled = this.daysOfWeekDisabled.split(/,\s*/);
      }
      this.daysOfWeekDisabled = $.map(this.daysOfWeekDisabled, function(d) {
        return parseInt(d, 10);
      });
      this.update();
      this.updateNavArrows();
    },

    setDatesDisabled: function(datesDisabled) {
      this.datesDisabled = datesDisabled || [];
      if (!$.isArray(this.datesDisabled)) {
        this.datesDisabled = this.datesDisabled.split(/,\s*/);
      }
      this.datesDisabled = $.map(this.datesDisabled, function(d) {
        return DPGlobal.parseDate(d, this.format, this.language).valueOf();
      });
      this.update();
      this.updateNavArrows();
    },

    place: function() {
      if (this.isInline) {return;}
      let zIndexes = [];
      this.element.parents().map(function() {
        if ($(this).css("z-index") != "auto") {
          zIndexes.push(parseInt($(this).css("z-index")));
        }
      });
      let zIndex = zIndexes.sort(function(a, b) { return a - b; }).pop() + 10;
      let textbox = this.component ? this.component : this.element;
      let offset = textbox.offset();
      let height = textbox.outerHeight() + parseInt(textbox.css("margin-top"));
      let width = textbox.outerWidth() + parseInt(textbox.css("margin-left"));
      let fullOffsetTop = offset.top + height;
      let offsetLeft = offset.left;
      this.picker.removeClass("datepicker-top datepicker-bottom");
      // can we show it on top?
      let canShowTop = ($(window).scrollTop() < offset.top - this.picker.outerHeight());
      let canShowBottom = (fullOffsetTop + this.picker.outerHeight()) < $(window).scrollTop() + $(window).height();
      // If the datepicker is going to be below the window, show it on top of the input if it fits
      if (!canShowBottom && canShowTop) {
        fullOffsetTop = offset.top - this.picker.outerHeight();
        this.picker.addClass("datepicker-top");
      }
      else {
        // Scroll up if we cannot show it on bottom or top (for mobile devices)
        if (!canShowBottom) {$(window).scrollTop(offset.top);}
        this.picker.addClass("datepicker-bottom");
      }

      // if the datepicker is going to go past the right side of the window, we want
      // to set the right position so the datepicker lines up with the textbox
      if (offset.left + this.picker.width() >= $(window).width()) {
        offsetLeft = (offset.left + width) - this.picker.width();
      }
      this.picker.css({
        top: fullOffsetTop,
        left: offsetLeft,
        zIndex: zIndex
      });
    },

    update: function() {
      let date,
          fromArgs = false;
      let currentVal = this.isInput ? this.element.val() : this.element.data("date") || this.element.find("input").val();
      if (arguments && arguments.length && (typeof arguments[0] === "string" || arguments[0] instanceof Date)) {
        date = arguments[0];
        fromArgs = true;
      }
      else {
        date = this.isInput ? this.element.val() : this.element.data("date") || this.element.find("input").val();
      }

      this.date = DPGlobal.parseDate(date, this.format, this.language);

      if (fromArgs) {
        this.setValue();
      } else if (currentVal == "") {
        this.element.trigger({
          type: "changeDate",
          date: null
        });
      }

      if (this.date < this.startDate) {
        this.viewDate = new Date(this.startDate.valueOf());
      } else if (this.date > this.endDate) {
        this.viewDate = new Date(this.endDate.valueOf());
      } else {
        this.viewDate = new Date(this.date.valueOf());
      }
      this.fill();
    },

    fillDow: function() {
      let dowCnt = this.weekStart,
          html = "<tr>";
      if (this.calendarWeeks) {
        let cell = '<th class="cw">&nbsp;</th>';
        html += cell;
        this.picker.find(".datepicker-days thead tr:first-child").prepend(cell);
      }
      while (dowCnt < this.weekStart + 7) {
        html += `<th class="dow">${dates[this.language].daysMin[(dowCnt++) % 7]}</th>`;
      }
      html += "</tr>";
      this.picker.find(".datepicker-days thead").append(html);
    },

    fillMonths: function() {
      let html = "",
          i = 0;
      while (i < 12) {
        html += `<span class="month">${dates[this.language].monthsShort[i++]}</span>`;
      }
      this.picker.find(".datepicker-months td").html(html);
    },

    fill: function() {
      if (this.date == null || this.viewDate == null) {
        return;
      }

      let d = new Date(this.viewDate.valueOf()),
          year = d.getUTCFullYear(),
          month = d.getUTCMonth(),
          dayMonth = d.getUTCDate(),
          hours = d.getUTCHours(),
          minutes = d.getUTCMinutes(),
          startYear = this.startDate !== -Infinity ? this.startDate.getUTCFullYear() : -Infinity,
          startMonth = this.startDate !== -Infinity ? this.startDate.getUTCMonth() : -Infinity,
          endYear = this.endDate !== Infinity ? this.endDate.getUTCFullYear() : Infinity,
          endMonth = this.endDate !== Infinity ? this.endDate.getUTCMonth() : Infinity,
          currentDate = this.date && UTCDate(this.date.getUTCFullYear(), this.date.getUTCMonth(), this.date.getUTCDate()).valueOf(),
          today = new Date(),
          titleFormat = dates[this.language].titleFormat || dates.en.titleFormat;
      // this.picker.find('.datepicker-days thead th.date-switch')
      //          .text(DPGlobal.formatDate(new UTCDate(year, month), titleFormat, this.language));

      this.picker.find(".datepicker-days thead th:eq(1)").
        text(`${dates[this.language].months[month]} ${year}`);
      this.picker.find(".datepicker-hours thead th:eq(1)").
        text(`${dayMonth} ${dates[this.language].months[month]} ${year}`);
      this.picker.find(".datepicker-minutes thead th:eq(1)").
        text(`${dayMonth} ${dates[this.language].months[month]} ${year}`);


      this.picker.find("tfoot th.today").
        text(dates[this.language].today).
        toggle(this.todayBtn !== false);
      this.updateNavArrows();
      this.fillMonths();
      let prevMonth = UTCDate(year, month - 1, 28, 0, 0, 0, 0),
          day = DPGlobal.getDaysInMonth(prevMonth.getUTCFullYear(), prevMonth.getUTCMonth());
      prevMonth.setUTCDate(day);
      prevMonth.setUTCDate(day - (prevMonth.getUTCDay() - this.weekStart + 7) % 7);
      let nextMonth = new Date(prevMonth.valueOf());
      nextMonth.setUTCDate(nextMonth.getUTCDate() + 42);
      nextMonth = nextMonth.valueOf();
      let html = [];
      let clsName;
      while (prevMonth.valueOf() < nextMonth) {
        if (prevMonth.getUTCDay() == this.weekStart) {
          html.push("<tr>");
          if (this.calendarWeeks) {
            // adapted from https://github.com/timrwood/moment/blob/master/moment.js#L128
            let a = new Date(prevMonth.getUTCFullYear(), prevMonth.getUTCMonth(), prevMonth.getUTCDate() - prevMonth.getDay() + 10 - (this.weekStart && this.weekStart % 7 < 5 && 7)),
                b = new Date(a.getFullYear(), 0, 4),
                calWeek = ~~((a - b) / 864e5 / 7 + 1.5);
            html.push(`<td class="cw">${calWeek}</td>`);
          }
        }
        clsName = ` ${this.onRender(prevMonth)} `;
        if (prevMonth.getUTCFullYear() < year || (prevMonth.getUTCFullYear() == year && prevMonth.getUTCMonth() < month)) {
          clsName += " old";
        } else if (prevMonth.getUTCFullYear() > year || (prevMonth.getUTCFullYear() == year && prevMonth.getUTCMonth() > month)) {
          clsName += " new";
        }
        // Compare internal UTC date with local today, not UTC today
        if (this.todayHighlight &&
                    prevMonth.getUTCFullYear() == today.getFullYear() &&
                    prevMonth.getUTCMonth() == today.getMonth() &&
                    prevMonth.getUTCDate() == today.getDate()) {
          clsName += " today";
        }
        if (currentDate && prevMonth.valueOf() == currentDate) {
          clsName += " active";
        }
        if (prevMonth.valueOf() < this.startDate || prevMonth.valueOf() > this.endDate ||
                    $.inArray(prevMonth.getUTCDay(), this.daysOfWeekDisabled) !== -1 ||
                    $.inArray(prevMonth.valueOf(), this.datesDisabled) !== -1) {
          clsName += " disabled";
        }
        html.push(`<td class="day${clsName}">${prevMonth.getUTCDate()}</td>`);
        if (prevMonth.getUTCDay() == this.weekEnd) {
          html.push("</tr>");
        }
        prevMonth.setUTCDate(prevMonth.getUTCDate() + 1);
      }
      this.picker.find(".datepicker-days tbody").empty().append(html.join(""));

      html = [];
      for (var i = 0; i < 24; i++) {
        var actual = UTCDate(year, month, dayMonth, i);
        clsName = "";
        // We want the previous hour for the startDate
        if ((actual.valueOf() + 3600000) < this.startDate || actual.valueOf() > this.endDate) {
          clsName += " disabled";
        } else if (hours == i) {
          clsName += " active";
        }
        html.push(`<span class="hour${clsName}">${i}:00</span>`);
      }
      this.picker.find(".datepicker-hours td").html(html.join(""));

      html = [];
      for (var i = 0; i < 60; i += this.minuteStep) {
        var actual = UTCDate(year, month, dayMonth, hours, i);
        clsName = "";
        if (actual.valueOf() < this.startDate || actual.valueOf() > this.endDate) {
          clsName += " disabled";
        } else if (Math.floor(minutes / this.minuteStep) == Math.floor(i / this.minuteStep)) {
          clsName += " active";
        }
        html.push(`<span class="minute${clsName}">${hours}:${i < 10 ? `0${i}` : i}</span>`);
      }
      this.picker.find(".datepicker-minutes td").html(html.join(""));


      let currentYear = this.date && this.date.getUTCFullYear();
      let months = this.picker.find(".datepicker-months").
        find("th:eq(1)").
        text(year).
        end().
        find("span").removeClass("active");
      if (currentYear && currentYear == year) {
        months.eq(this.date.getUTCMonth()).addClass("active");
      }
      if (year < startYear || year > endYear) {
        months.addClass("disabled");
      }
      if (year == startYear) {
        months.slice(0, startMonth).addClass("disabled");
      }
      if (year == endYear) {
        months.slice(endMonth + 1).addClass("disabled");
      }

      html = "";
      year = parseInt(year / 10, 10) * 10;
      let yearCont = this.picker.find(".datepicker-years").
        find("th:eq(1)").
        text(`${year}-${year + 9}`).
        end().
        find("td");
      year -= 1;
      for (var i = -1; i < 11; i++) {
        html += `<span class="year${i == -1 || i == 10 ? " old" : ""}${currentYear == year ? " active" : ""}${year < startYear || year > endYear ? " disabled" : ""}">${year}</span>`;
        year += 1;
      }
      yearCont.html(html);
    },

    updateNavArrows: function() {
      let d = new Date(this.viewDate),
          year = d.getUTCFullYear(),
          month = d.getUTCMonth(),
          day = d.getUTCDate(),
          hour = d.getUTCHours();
      switch (this.viewMode) {
      case 0:
        if (this.startDate !== -Infinity && year <= this.startDate.getUTCFullYear() && month <= this.startDate.getUTCMonth() && day <= this.startDate.getUTCDate() && hour <= this.startDate.getUTCHours()) {
          this.picker.find(".prev").css({
            visibility: "hidden"
          });
        } else {
          this.picker.find(".prev").css({
            visibility: "visible"
          });
        }
        if (this.endDate !== Infinity && year >= this.endDate.getUTCFullYear() && month >= this.endDate.getUTCMonth() && day >= this.endDate.getUTCDate() && hour >= this.endDate.getUTCHours()) {
          this.picker.find(".next").css({
            visibility: "hidden"
          });
        } else {
          this.picker.find(".next").css({
            visibility: "visible"
          });
        }
        break;
      case 1:
        if (this.startDate !== -Infinity && year <= this.startDate.getUTCFullYear() && month <= this.startDate.getUTCMonth() && day <= this.startDate.getUTCDate()) {
          this.picker.find(".prev").css({
            visibility: "hidden"
          });
        } else {
          this.picker.find(".prev").css({
            visibility: "visible"
          });
        }
        if (this.endDate !== Infinity && year >= this.endDate.getUTCFullYear() && month >= this.endDate.getUTCMonth() && day >= this.endDate.getUTCDate()) {
          this.picker.find(".next").css({
            visibility: "hidden"
          });
        } else {
          this.picker.find(".next").css({
            visibility: "visible"
          });
        }
        break;
      case 2:
        if (this.startDate !== -Infinity && year <= this.startDate.getUTCFullYear() && month <= this.startDate.getUTCMonth()) {
          this.picker.find(".prev").css({
            visibility: "hidden"
          });
        } else {
          this.picker.find(".prev").css({
            visibility: "visible"
          });
        }
        if (this.endDate !== Infinity && year >= this.endDate.getUTCFullYear() && month >= this.endDate.getUTCMonth()) {
          this.picker.find(".next").css({
            visibility: "hidden"
          });
        } else {
          this.picker.find(".next").css({
            visibility: "visible"
          });
        }
        break;
      case 3:
      case 4:
        if (this.startDate !== -Infinity && year <= this.startDate.getUTCFullYear()) {
          this.picker.find(".prev").css({
            visibility: "hidden"
          });
        } else {
          this.picker.find(".prev").css({
            visibility: "visible"
          });
        }
        if (this.endDate !== Infinity && year >= this.endDate.getUTCFullYear()) {
          this.picker.find(".next").css({
            visibility: "hidden"
          });
        } else {
          this.picker.find(".next").css({
            visibility: "visible"
          });
        }
        break;
      }
    },

    click: function(e) {
      e.stopPropagation();
      e.preventDefault();

      if ($(e.target).hasClass("datepicker-close") || $(e.target).parent().hasClass("datepicker-close")) {
        this.hide();
      }

      let target = $(e.target).closest("span, td, th");
      if (target.length == 1) {
        if (target.is(".disabled")) {
          this.element.trigger({
            type: "outOfRange",
            date: this.viewDate,
            startDate: this.startDate,
            endDate: this.endDate
          });
          return;
        }

        switch (target[0].nodeName.toLowerCase()) {
        case "th":
          switch (target[0].className) {
          case "date-switch":
            this.showMode(1);
            break;
          case "prev":
          case "next":
            var dir = DPGlobal.modes[this.viewMode].navStep * (target[0].className == "prev" ? -1 : 1);
            switch (this.viewMode) {
            case 0:
              this.viewDate = this.moveHour(this.viewDate, dir);
              break;
            case 1:
              this.viewDate = this.moveDate(this.viewDate, dir);
              break;
            case 2:
              this.viewDate = this.moveMonth(this.viewDate, dir);
              break;
            case 3:
            case 4:
              this.viewDate = this.moveYear(this.viewDate, dir);
              break;
            }
            this.fill();
            break;
          case "today":
            var date = new Date();
            date = UTCDate(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());

            this.viewMode = this.startViewMode;
            this.showMode(0);
            this._setDate(date);
            break;
          }
          break;
        case "span":
          if (!target.is(".disabled")) {
            if (target.is(".month")) {
              if (this.minView === 3) {
                var month = target.parent().find("span").index(target) || 0;
                var year = this.viewDate.getUTCFullYear(),
                    day = 1,
                    hours = this.viewDate.getUTCHours(),
                    minutes = this.viewDate.getUTCMinutes(),
                    seconds = this.viewDate.getUTCSeconds();
                this._setDate(UTCDate(year, month, day, hours, minutes, seconds, 0));
              } else {
                this.viewDate.setUTCDate(1);
                var month = target.parent().find("span").index(target);
                this.viewDate.setUTCMonth(month);
                this.element.trigger({
                  type: "changeMonth",
                  date: this.viewDate
                });
              }
            } else if (target.is(".year")) {
              if (this.minView === 4) {
                var year = parseInt(target.text(), 10) || 0;
                var month = 0,
                    day = 1,
                    hours = this.viewDate.getUTCHours(),
                    minutes = this.viewDate.getUTCMinutes(),
                    seconds = this.viewDate.getUTCSeconds();
                this._setDate(UTCDate(year, month, day, hours, minutes, seconds, 0));
              } else {
                this.viewDate.setUTCDate(1);
                var year = parseInt(target.text(), 10) || 0;
                this.viewDate.setUTCFullYear(year);
                this.element.trigger({
                  type: "changeYear",
                  date: this.viewDate
                });
              }
            } else if (target.is(".hour")) {
              var hours = parseInt(target.text(), 10) || 0;
              var year = this.viewDate.getUTCFullYear(),
                  month = this.viewDate.getUTCMonth(),
                  day = this.viewDate.getUTCDate(),
                  minutes = this.viewDate.getUTCMinutes(),
                  seconds = this.viewDate.getUTCSeconds();
              this._setDate(UTCDate(year, month, day, hours, minutes, seconds, 0));
            } else if (target.is(".minute")) {
              var minutes = parseInt(target.text().substr(target.text().indexOf(":") + 1), 10) || 0;
              var year = this.viewDate.getUTCFullYear(),
                  month = this.viewDate.getUTCMonth(),
                  day = this.viewDate.getUTCDate(),
                  hours = this.viewDate.getUTCHours(),
                  seconds = this.viewDate.getUTCSeconds();
              this._setDate(UTCDate(year, month, day, hours, minutes, seconds, 0));
            }


            if (this.viewMode != 0) {


              var oldViewMode = this.viewMode;
              this.showMode(-1);
              this.fill();
              if (oldViewMode == this.viewMode && this.autoclose) {
                this.hide();
              }
            } else {
              this.fill();
              if (this.autoclose) {
                this.hide();
              }
            }
          }
          break;
        case "td":


          if (target.is(".day") && !target.is(".disabled")) {
            var day = parseInt(target.text(), 10) || 1;
            var year = this.viewDate.getUTCFullYear(),
                month = this.viewDate.getUTCMonth(),
                hours = this.viewDate.getUTCHours(),
                minutes = this.viewDate.getUTCMinutes(),
                seconds = this.viewDate.getUTCSeconds();
            if (target.is(".old")) {
              if (month === 0) {
                month = 11;
                year -= 1;
              } else {
                month -= 1;
              }
            } else if (target.is(".new")) {
              if (month == 11) {
                month = 0;
                year += 1;
              } else {
                month += 1;
              }
            }
            this._setDate(UTCDate(year, month, day, hours, minutes, seconds, 0));
          }


          var oldViewMode = this.viewMode;


          this.showMode(-1);


          this.fill();
          if (oldViewMode == this.viewMode && this.autoclose) {
            this.hide();
          }
          break;
        }
      }
    },

    _setDate: function(date, which) {

      if (!which || which == "date")
      {this.date = date;}
      if (!which || which == "view")
      {this.viewDate = date;}
      this.fill();
      this.setValue();
      this.element.trigger({
        type: "changeDate",
        date: this.date
      });
      let element;
      if (this.isInput) {
        element = this.element;
      } else if (this.component) {
        element = this.element.find("input");
      }
      if (element) {
        element.change();
        if (this.autoclose && (!which || which == "date")) {
          // this.hide();
        }
      }
    },

    moveHour: function(date, dir) {
      if (!dir) {return date;}
      let new_date = new Date(date.valueOf());
      dir = dir > 0 ? 1 : -1;
      new_date.setUTCHours(new_date.getUTCHours() + dir);
      return new_date;
    },

    moveDate: function(date, dir) {
      if (!dir) {return date;}
      let new_date = new Date(date.valueOf());
      dir = dir > 0 ? 1 : -1;
      new_date.setUTCDate(new_date.getUTCDate() + dir);
      return new_date;
    },

    moveMonth: function(date, dir) {
      if (!dir) {return date;}
      let new_date = new Date(date.valueOf()),
          day = new_date.getUTCDate(),
          month = new_date.getUTCMonth(),
          mag = Math.abs(dir),
          new_month, test;
      dir = dir > 0 ? 1 : -1;
      if (mag == 1) {
        test = dir == -1
        // If going back one month, make sure month is not current month
        // (eg, Mar 31 -> Feb 31 == Feb 28, not Mar 02)
          ? function() {
            return new_date.getUTCMonth() == month;
          }
        // If going forward one month, make sure month is as expected
        // (eg, Jan 31 -> Feb 31 == Feb 28, not Mar 02)
          : function() {
            return new_date.getUTCMonth() != new_month;
          };
        new_month = month + dir;
        new_date.setUTCMonth(new_month);
        // Dec -> Jan (12) or Jan -> Dec (-1) -- limit expected date to 0-11
        if (new_month < 0 || new_month > 11)
        {new_month = (new_month + 12) % 12;}
      } else {
        // For magnitudes >1, move one month at a time...
        for (let i = 0; i < mag; i++)
        // ...which might decrease the day (eg, Jan 31 to Feb 28, etc)...
        {new_date = this.moveMonth(new_date, dir);}
        // ...then reset the day, keeping it in the new month
        new_month = new_date.getUTCMonth();
        new_date.setUTCDate(day);
        test = function() {
          return new_month != new_date.getUTCMonth();
        };
      }
      // Common date-resetting loop -- if date is beyond end of month, make it
      // end of month
      while (test()) {
        new_date.setUTCDate(--day);
        new_date.setUTCMonth(new_month);
      }
      return new_date;
    },

    moveYear: function(date, dir) {
      return this.moveMonth(date, dir * 12);
    },

    dateWithinRange: function(date) {
      return date >= this.startDate && date <= this.endDate;
    },

    keydown: function(e) {
      if (!this.keyboardNavigation) {
        return true;
      }
      if (this.picker.is(":not(:visible)")) {
        if (e.keyCode == 27) // allow escape to hide and re-show picker
        {this.show();}
        return;
      }
      let dateChanged = false,
          day, dir, month,
          newDate, newViewDate;
      switch (e.keyCode) {
      case 27: // escape
        this.hide();
        e.preventDefault();
        break;
      case 37: // left
      case 39: // right
        if (!this.keyboardNavigation) {break;}
        dir = e.keyCode == 37 ? -1 : 1;
        if (e.ctrlKey) {
          newDate = this.moveYear(this.date, dir);
          newViewDate = this.moveYear(this.viewDate, dir);
        } else if (e.shiftKey) {
          newDate = this.moveMonth(this.date, dir);
          newViewDate = this.moveMonth(this.viewDate, dir);
        } else {
          newDate = new Date(this.date.valueOf());
          newDate.setUTCDate(this.date.getUTCDate() + dir);
          newViewDate = new Date(this.viewDate.valueOf());
          newViewDate.setUTCDate(this.viewDate.getUTCDate() + dir);
        }
        if (this.dateWithinRange(newDate)) {
          this.date = newDate;
          this.viewDate = newViewDate;
          this.setValue();
          this.update();
          e.preventDefault();
          dateChanged = true;
        }
        break;
      case 38: // up
      case 40: // down
        if (!this.keyboardNavigation) {break;}
        dir = e.keyCode == 38 ? -1 : 1;
        if (e.ctrlKey) {
          newDate = this.moveYear(this.date, dir);
          newViewDate = this.moveYear(this.viewDate, dir);
        } else if (e.shiftKey) {
          newDate = this.moveMonth(this.date, dir);
          newViewDate = this.moveMonth(this.viewDate, dir);
        } else {
          newDate = new Date(this.date.valueOf());
          newDate.setUTCDate(this.date.getUTCDate() + dir * 7);
          newViewDate = new Date(this.viewDate.valueOf());
          newViewDate.setUTCDate(this.viewDate.getUTCDate() + dir * 7);
        }
        if (this.dateWithinRange(newDate)) {
          this.date = newDate;
          this.viewDate = newViewDate;
          this.setValue();
          this.update();
          e.preventDefault();
          dateChanged = true;
        }
        break;
      case 13: // enter
        this.hide();
        e.preventDefault();
        break;
      case 9: // tab
        this.hide();
        break;
      }
      if (dateChanged) {
        this.element.trigger({
          type: "changeDate",
          date: this.date
        });
        let element;
        if (this.isInput) {
          element = this.element;
        } else if (this.component) {
          element = this.element.find("input");
        }
        if (element) {
          element.change();
        }
      }
    },

    showMode: function(dir) {

      if (dir) {
        let newViewMode = Math.max(0, Math.min(DPGlobal.modes.length - 1, this.viewMode + dir));
        if (newViewMode >= this.minView && newViewMode <= this.maxView) {
          this.viewMode = newViewMode;
        }
      }

      /*
                vitalets: fixing bug of very special conditions:
                jquery 1.7.1 + webkit + show inline datepicker in bootstrap popover.
                Method show() does not set display css correctly and datepicker is not shown.
                Changed to .css('display', 'block') solve the problem.
                See https://github.com/vitalets/x-editable/issues/37

                In jquery 1.7.2+ everything works fine.
            */
      // this.picker.find('>div').hide().filter('.datepicker-'+DPGlobal.modes[this.viewMode].clsName).show();
      this.picker.find(">div").hide().filter(`.datepicker-${DPGlobal.modes[this.viewMode].clsName}`).css("display", "block");
      this.updateNavArrows();
    },

    changeViewDate: function(date) {
      this.date = date;
      this.viewDate = date;
      this.fill();
    },

    reset: function(e) {
      this._setDate(null, "date");
    }
  };

  $.fn.fdatepicker = function(option) {
    let args = Array(...arguments);
    args.shift();
    return this.each(function() {
      let $this = $(this),
          data = $this.data("datepicker"),
          options = typeof option === "object" && option;
      if (!data) {
        $this.data("datepicker", (data = new Datepicker(this, $.extend({}, $.fn.fdatepicker.defaults, options))));
      }
      if (typeof option === "string" && typeof data[option] === "function") {
        data[option](...args);
      }
    });
  };

  $.fn.fdatepicker.defaults = {
    onRender: function(date) {
      return "";
    }
  };
  $.fn.fdatepicker.Constructor = Datepicker;
  var dates = $.fn.fdatepicker.dates = {
    "en": {
      days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
      daysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      daysMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
      months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      monthsShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      today: "Today",
      titleFormat: "MM yyyy"
    }
  };

  var DPGlobal = {
    modes: [{
      clsName: "minutes",
      navFnc: "Hours",
      navStep: 1
    }, {
      clsName: "hours",
      navFnc: "Date",
      navStep: 1
    }, {
      clsName: "days",
      navFnc: "Month",
      navStep: 1
    }, {
      clsName: "months",
      navFnc: "FullYear",
      navStep: 1
    }, {
      clsName: "years",
      navFnc: "FullYear",
      navStep: 10
    }],
    isLeapYear: function(year) {
      return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0));
    },
    getDaysInMonth: function(year, month) {
      return [31, (DPGlobal.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
    },
    validParts: /hh?|ii?|ss?|dd?|mm?|MM?|yy(?:yy)?/g,
    nonpunctuation: /[^ -\/:-@\[\u3400-\u9fff-`{-~\t\n\r]+/g,
    parseFormat: function(format) {
      // IE treats \0 as a string end in inputs (truncating the value),
      // so it's a bad format delimiter, anyway
      let separators = format.replace(this.validParts, "\0").split("\0"),
          parts = format.match(this.validParts);
      if (!separators || !separators.length || !parts || parts.length === 0) {
        throw new Error("Invalid date format.");
      }
      this.formatText = format;
      return {
        separators: separators,
        parts: parts
      };
    },
    parseDate: function(date, format, language) {
      if (date instanceof Date) {return new Date(date.valueOf() - date.getTimezoneOffset() * 60000);}
      if (/^\d{4}\-\d{1,2}\-\d{1,2}$/.test(date)) {
        format = this.parseFormat("yyyy-mm-dd");
      }
      if (/^\d{4}\-\d{1,2}\-\d{1,2}[T ]\d{1,2}\:\d{1,2}$/.test(date)) {
        format = this.parseFormat("yyyy-mm-dd hh:ii");
      }
      if (/^\d{4}\-\d{1,2}\-\d{1,2}[T ]\d{1,2}\:\d{1,2}\:\d{1,2}[Z]{0,1}$/.test(date)) {
        format = this.parseFormat("yyyy-mm-dd hh:ii:ss");
      }
      if (/^[-+]\d+[dmwy]([\s,]+[-+]\d+[dmwy])*$/.test(date)) {
        var part_re = /([-+]\d+)([dmwy])/,
            parts = date.match(/([-+]\d+)([dmwy])/g),
            part, dir;
        date = new Date();
        for (var i = 0; i < parts.length; i++) {
          part = part_re.exec(parts[i]);
          dir = parseInt(part[1]);
          switch (part[2]) {
          case "d":
            date.setUTCDate(date.getUTCDate() + dir);
            break;
          case "m":
            date = Datetimepicker.prototype.moveMonth.call(Datetimepicker.prototype, date, dir);
            break;
          case "w":
            date.setUTCDate(date.getUTCDate() + dir * 7);
            break;
          case "y":
            date = Datetimepicker.prototype.moveYear.call(Datetimepicker.prototype, date, dir);
            break;
          }
        }
        return UTCDate(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
      }
      var parts = date && date.match(this.nonpunctuation) || [],
          date = new Date(),
          parsed = {},
          setters_order = ["hh", "h", "ii", "i", "ss", "s", "yyyy", "yy", "M", "MM", "m", "mm", "d", "dd"],
          setters_map = {
            hh: function(d, v) {
              return d.setUTCHours(v);
            },
            h: function(d, v) {
              return d.setUTCHours(v);
            },
            ii: function(d, v) {
              return d.setUTCMinutes(v);
            },
            i: function(d, v) {
              return d.setUTCMinutes(v);
            },
            ss: function(d, v) {
              return d.setUTCSeconds(v);
            },
            s: function(d, v) {
              return d.setUTCSeconds(v);
            },
            yyyy: function(d, v) {
              return d.setUTCFullYear(v);
            },
            yy: function(d, v) {
              return d.setUTCFullYear(2000 + v);
            },
            m: function(d, v) {
              v -= 1;
              while (v < 0) {v += 12;}
              v %= 12;
              d.setUTCMonth(v);
              while (d.getUTCMonth() != v)
              {d.setUTCDate(d.getUTCDate() - 1);}
              return d;
            },
            d: function(d, v) {
              return d.setUTCDate(v);
            }
          },
          val, filtered, part;
      setters_map.M = setters_map.MM = setters_map.mm = setters_map.m;
      setters_map.dd = setters_map.d;
      date = UTCDate(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0); // date.getHours(), date.getMinutes(), date.getSeconds());
      if (parts.length == format.parts.length) {
        for (var i = 0, cnt = format.parts.length; i < cnt; i++) {
          val = parseInt(parts[i], 10);
          part = format.parts[i];
          if (isNaN(val)) {
            switch (part) {
            case "MM":
              filtered = $(dates[language].months).filter(function() {
                let m = this.slice(0, parts[i].length),
                    p = parts[i].slice(0, m.length);
                return m == p;
              });
              val = $.inArray(filtered[0], dates[language].months) + 1;
              break;
            case "M":
              filtered = $(dates[language].monthsShort).filter(function() {
                let m = this.slice(0, parts[i].length),
                    p = parts[i].slice(0, m.length);
                return m == p;
              });
              val = $.inArray(filtered[0], dates[language].monthsShort) + 1;
              break;
            }
          }
          parsed[part] = val;
        }
        for (var i = 0, s; i < setters_order.length; i++) {
          s = setters_order[i];
          if (s in parsed && !isNaN(parsed[s]))
          {setters_map[s](date, parsed[s])}
        }
      }
      return date;
    },
    formatDate: function(date, format, language) {
      if (date == null) {
        return "";
      }
      let val = {
        h: date.getUTCHours(),
        i: date.getUTCMinutes(),
        s: date.getUTCSeconds(),
        d: date.getUTCDate(),
        m: date.getUTCMonth() + 1,
        M: dates[language].monthsShort[date.getUTCMonth()],
        MM: dates[language].months[date.getUTCMonth()],
        yy: date.getUTCFullYear().toString().substring(2),
        yyyy: date.getUTCFullYear()
      };
      val.hh = (val.h < 10 ? "0" : "") + val.h;
      val.ii = (val.i < 10 ? "0" : "") + val.i;
      val.ss = (val.s < 10 ? "0" : "") + val.s;
      val.dd = (val.d < 10 ? "0" : "") + val.d;
      val.mm = (val.m < 10 ? "0" : "") + val.m;
      var date = [],
          seps = $.extend([], format.separators);
      for (let i = 0, cnt = format.parts.length; i < cnt; i++) {
        if (seps.length)
        {date.push(seps.shift())}
        date.push(val[format.parts[i]]);
      }
      return date.join("");
    },
    convertViewMode: function(viewMode) {
      switch (viewMode) {
      case 4:
      case "decade":
        viewMode = 4;
        break;
      case 3:
      case "year":
        viewMode = 3;
        break;
      case 2:
      case "month":
        viewMode = 2;
        break;
      case 1:
      case "day":
        viewMode = 1;
        break;
      case 0:
      case "hour":
        viewMode = 0;
        break;
      }

      return viewMode;
    },
    headTemplate: function(leftArrow, rightArrow) {return (`${"<thead>" +
            "<tr>" +
            '<th class="prev">'}${leftArrow}</th>` +
            "<th colspan=\"5\" class=\"date-switch\"></th>" +
            `<th class="next">${rightArrow}</th>` +
            "</tr>" +
            "</thead>")},
    contTemplate: '<tbody><tr><td colspan="7"></td></tr></tbody>',
    footTemplate: '<tfoot><tr><th colspan="7" class="today"></th></tr></tfoot>'
  };
  DPGlobal.template = function(leftArrow, rightArrow, closeIcon) {return (`${'<div class="datepicker">' +
        '<div class="datepicker-minutes">' +
        '<table class=" table-condensed">'}${
    DPGlobal.headTemplate(leftArrow, rightArrow)
  }${DPGlobal.contTemplate
  }${DPGlobal.footTemplate
  }</table>` +
        "</div>" +
        "<div class=\"datepicker-hours\">" +
        `<table class=" table-condensed">${
          DPGlobal.headTemplate(leftArrow, rightArrow)
        }${DPGlobal.contTemplate
        }${DPGlobal.footTemplate
        }</table>` +
        "</div>" +
        "<div class=\"datepicker-days\">" +
        `<table class=" table-condensed">${
          DPGlobal.headTemplate(leftArrow, rightArrow)
        }<tbody></tbody>${
          DPGlobal.footTemplate
        }</table>` +
        "</div>" +
        "<div class=\"datepicker-months\">" +
        `<table class="table-condensed">${
          DPGlobal.headTemplate(leftArrow, rightArrow)
        }${DPGlobal.contTemplate
        }${DPGlobal.footTemplate
        }</table>` +
        "</div>" +
        "<div class=\"datepicker-years\">" +
        `<table class="table-condensed">${
          DPGlobal.headTemplate(leftArrow, rightArrow)
        }${DPGlobal.contTemplate
        }${DPGlobal.footTemplate
        }</table>` +
        "</div>" +
        `<a class="button datepicker-close tiny alert right" style="width:auto;">${closeIcon}</a>` +
        "</div>")};

  $.fn.fdatepicker.DPGlobal = DPGlobal;

}(window.jQuery));
