
export default () => {
    if (typeof window.$ != 'undefined')
        return Promise.resolve();
    
    return new Promise((resolve) => {
        var waitForJQuery = setInterval(function () {
            if (typeof window.$ != 'undefined') {
                $(document).on('zf-initialized', () => {
                    // Foundation is initialized, we can safely do things.
                    resolve();
                });
                clearInterval(waitForJQuery);
            }
        }, 10);
    })
}